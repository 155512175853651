/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { trackEvent } from '../../utils/segmentTrack'
import useUrlParam from '../../utils/useUrlParam'

import Layout from '../../components/Layout'

const Wrapper = styled.div`
  height: 100vh;
`

const SecondaryScan = () => {
  const windowGlobal = typeof window !== `undefined` && window
  const [inputUrl] = useState(windowGlobal ? useUrlParam(window.location.search, 'url') : false)

  windowGlobal && navigate(encodeURI(`/scan/results/?url=${inputUrl}&&partner=true`))

  let sessionEmail
  const hsFormData = windowGlobal && window.sessionStorage.getItem('hs-form-data')
  if (hsFormData) {
    const parsedData = JSON.parse(hsFormData)
    const mappedData = mapValues(keyBy(parsedData, 'name'), 'value')
    sessionEmail = mappedData.email || ''
  }

  useEffect(() => {
    trackEvent(`Additional Site Scan Started`, {
      category: 'site_scan',
      site_url: inputUrl,
      email: sessionEmail,
    })
  }, [])

  return (
    <Layout hideHeaderFooterNav showCTA homePageLink>
      <Wrapper />
    </Layout>
  )
}

export default SecondaryScan
